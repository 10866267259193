import { useState, useEffect } from "react";
import {
  AppProvider,
  Form,
  FormLayout,
  Spinner,
  TextField,
  Page,
  Card,
  Button,
  Layout,
} from "@shopify/polaris";
import Home from "./home";
import api from "./api";
import "@shopify/polaris/build/esm/styles.css";
import { QueryClientProvider } from "react-query";

interface LoginReq {
  username: string;
  password: string;
}

const LoginForm = ({
  handleSubmit,
}: {
  handleSubmit: (username: string, password: string) => void;
}) => {
  const [data, setData] = useState<LoginReq>({ username: "", password: "" });

  return (
    <Card sectioned>
      <Form
        noValidate
        onSubmit={() => handleSubmit(data.username, data.password)}
      >
        <FormLayout>
          <TextField
            value={data.username}
            onChange={(username) => setData({ ...data, username })}
            label="Username"
            type="text"
            autoComplete="off"
          />
          <TextField
            value={data.password}
            onChange={(password) => setData({ ...data, password })}
            label="Password"
            type="password"
            autoComplete="off"
          />

          <Button submit>Submit</Button>
        </FormLayout>
      </Form>
    </Card>
  );
};

const App = () => {
  const [authed, setAuthed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setAuthed(await api.checkAuth());
      setLoading(false);
    })();
  }, []);

  const login = async (username: string, password: string) => {
    setLoading(true);
    const authed = await api.login(username, password);
    setAuthed(authed);
    setLoading(false);
  };

  return (
    <QueryClientProvider client={api.queryClient}>
      <AppProvider
        i18n={{
          Polaris: {
            Avatar: {
              label: "Avatar",
              labelWithInitials: "Avatar with initials {initials}",
            },
            Frame: { skipToContent: "Skip to content" },
            TopBar: {
              toggleMenuLabel: "Toggle menu",
              SearchField: {
                clearButtonLabel: "Clear",
                search: "Search",
              },
            },
          },
        }}
      >
        {loading && (
          <Page fullWidth>
            <Layout>
              <Layout.Section>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <Spinner accessibilityLabel="Spinner example" size="large" />
                </div>
              </Layout.Section>
            </Layout>
          </Page>
        )}

        {!loading && !authed && (
          <Page fullWidth>
            <LoginForm handleSubmit={login} />
          </Page>
        )}

        {!loading && authed && <Home />}
      </AppProvider>
    </QueryClientProvider>
  );
};

export default App;
