import adapters from "./adapters";
import {
  AccountSummary,
  Categories,
  TimeseriesPlotlyDatasets,
  Transactions,
  TransactionsQuery,
  UpdateTransactionMetadata,
} from "../models";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  QueryClient,
  UseMutationResult,
} from "react-query";
import { formatDate } from "../utils";

const BASE = "https://bankapi-xq4n34hfca-ts.a.run.app";
//const BASE = "http://localhost:3000";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: () => {
        return false;
      },
    },
  },
});

const getRequestInit = (): RequestInit => {
  const headers = {
    "Content-Type": "application/json",
  };
  const authorization = localStorage.getItem("Authorization");
  if (authorization) {
    headers["Authorization"] = authorization;
  }
  return {
    credentials: "include",
    headers: new Headers(headers),
    mode: "cors",
  };
};

const fetchJSON = async (url: string, request?: RequestInit): Promise<any> => {
  let json: any;
  let resp: Response;

  try {
    resp = await fetch(url, { ...getRequestInit(), ...request });
  } catch (err) {
    // TOOD: make this compatible with latest typescript
    // err.message = `Failed to fetch ${url}: ${err.message}`;
    throw err;
  }

  if (resp.status !== 200) {
    throw new Error(`${url} returned with HTTP status ${resp.status}`);
  }

  try {
    json = await resp.json();
  } catch (err) {
    // TOOD: make this compatible with latest typescript
    //err.message = `Failed to parse ${url} response as json ${err.message}`;
    throw err;
  }

  return json;
};

const checkAuth = async () => {
  return await fetch(`${BASE}/authenticate`, getRequestInit()).then(
    (resp) => resp.status === 200
  );
};

const login = async (username: string, password: string): Promise<boolean> => {
  const resp = await fetch(`${BASE}/authenticate`, {
    headers: new Headers({ "Content-Type": "application/json" }),
    method: "POST",
    body: JSON.stringify({ username, password }),
    credentials: "include",
  });
  if (resp.status !== 200) {
    return false;
  }

  localStorage.setItem("Authorization", `${username}:${password}`);
  return true;
};

const useGetTimeseries = (
  startDate: Date
): UseQueryResult<TimeseriesPlotlyDatasets, Error> => {
  const formattedStartDate = formatDate(startDate as Date);

  return useQuery(
    ["timeseries", formattedStartDate],
    async ({ queryKey: [_, startDate] }) => {
      return adapters.adaptPlotlyTimeseries(
        await fetchJSON(`${BASE}/bank/timeseries?start_date=${startDate}`)
      );
    },
    { enabled: true }
  );
};

const useGetTransactions = (
  query?: TransactionsQuery
): UseQueryResult<Transactions, Error> => {
  const queryStr = JSON.stringify(query);

  return useQuery(
    ["transactions", queryStr],
    async () => {
      const url = query
        ? `${BASE}/bank/transactions/query`
        : `${BASE}/bank/transactions`;

      const opts = query && {
        method: "POST",
        body: JSON.stringify({
          start_date: query.startDate,
          end_date: query.endDate,
          account_ids: query.accountIDs,
        }),
      };

      const json = await fetchJSON(url, opts);
      const list = json.transactions
        ? json.transactions.map(adapters.adaptTransaction)
        : [];

      return {
        list,
        count: json.count || undefined,
        amountSum: json.amount_sum || undefined,
        creditAmount: json.credit_amount || undefined,
        credits: json.credits || undefined,
        debitAmount: json.debit_amount || undefined,
        debits: json.debits || undefined,
        categoryBreakdowns: json.category_breakdowns
          ? json.category_breakdowns.map(adapters.adaptCategoryBreakdown)
          : undefined,
      };
    },
    { enabled: true }
  );
};

const useGetAccounts = (): UseQueryResult<AccountSummary, Error> => {
  return useQuery(
    ["accounts"],
    async () => {
      return adapters.adaptAccountSummary(
        await fetchJSON(`${BASE}/bank/accounts`)
      );
    },
    { enabled: true }
  );
};

const useGetCategories = (): UseQueryResult<Categories, Error> => {
  return useQuery(
    [],
    async () => {
      return await fetchJSON(`${BASE}/bank/categories`);
    },
    { enabled: true }
  );
};

const useUpdateTransactionMetadata = (): UseMutationResult<
  void,
  unknown,
  UpdateTransactionMetadata,
  unknown
> => {
  return useMutation(
    async ({ transactionID, categoryID, description }) => {
      const opts = {
        method: "POST",
        body: JSON.stringify({
          transaction_id: transactionID,
          category_id: {
            string: categoryID,
            valid: !!categoryID,
          },
          description: {
            string: description,
            valid: !!description,
          },
        }),
      };

      let resp: Response;

      const url = `${BASE}/bank/transactions/metadata`;

      try {
        resp = await fetch(url, { ...getRequestInit(), ...opts });
      } catch (err) {
        // TOOD: make this compatible with latest typescript
        // err.message = `Failed to fetch ${url}: ${err.message}`;
        throw err;
      }

      if (resp.status !== 200) {
        throw new Error(`${url} returned with HTTP status ${resp.status}`);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("transactions");
      },
    }
  );
};

const useSyncResource = (path: string): UseMutationResult<
  void,
  unknown,
  void,
  unknown
> => {
  return useMutation(async () => {
    const url = `${BASE}/${path}`;

    try {
      const resp = await fetch(url, { ...getRequestInit(), method: "POST" });
      if (resp.status !== 200) {
        throw new Error(`${url} returned with HTTP status ${resp.status}`);
      }
    } catch (err) {
      throw err;
    }
  }, {});
};

const useFetchVanguard = (): UseMutationResult<
  string,
  unknown,
  void,
  unknown
> => {
  return useMutation(async () => {
    const url = `${BASE}/fetch/vanguard/login`;
    let resp: Response;

    try {
      resp = await fetch(url, { ...getRequestInit(), method: "POST" });
    } catch (err) {
      throw err;
    }
    if (resp.status !== 200) {
      throw new Error(`${url} returned with HTTP status ${resp.status}`);
    }

    const json = await resp.json();
    return json.mfa_token as string;
  }, {});
};

const useVanguardOTP = (): UseMutationResult<
  void,
  unknown,
  { token: string; otp: string },
  unknown
> => {
  return useMutation(async ({ token, otp }) => {
    const url = `${BASE}/fetch/vanguard/otp`;
    let resp: Response;

    const body = JSON.stringify({
      mfa_token: token,
      otp_code: otp,
    });

    try {
      resp = await fetch(url, { ...getRequestInit(), body, method: "POST" });
    } catch (err) {
      throw err;
    }
    if (resp.status !== 200) {
      throw new Error(`${url} returned with HTTP status ${resp.status}`);
    }
  }, {});
};

const e = {
  queryClient,
  checkAuth,
  login,
  useGetTransactions,
  useGetAccounts,
  useGetTimeseries,
  useGetCategories,
  useUpdateTransactionMetadata,
  useFetchVanguard,
  useVanguardOTP,
  useSyncResource,
};

export default e;
